@import url('https://fonts.googleapis.com/css?family=Kanit:100,400,700|Montserrat:200,400,700');

body{background:#eee;}
body,input{
  font-family: Montserrat;
}

h1,h2,h3,h4,h5,h6{
  text-align:left;
  font-family:Kanit;
}

h1{
  font-size: 7vw;
  margin: 10px 0 10px;
  line-height: .9;
}

.info-links{
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 0;

}

.panel{
  color:#333;
  padding: 20px;
  line-height: 1.6;
  background: #fff;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 6px #ccc;
}

/* HEADERS */
.logo{
  animation: App-logo-spin infinite 90s linear;
}

.home.logo {
  height: 25vw;
  max-height:180px;
  pointer-events: none;
}

.app.logo {
  height: 60px;
  pointer-events: none;
  float:left;
  padding: 30px;
}

.app-header .bahaisongstext{
  margin-left:-20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header{
  background-color: #282c34;
  display: flex;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.home-header {
  text-align:center;
  flex-direction: column;
  padding:5vw;
}

.app-header {
  height: 80px;
}

.app-header a{
    align-items: center;
    display: flex;
    color: #fff;
    text-decoration: none;
}

/* HOME */

.search{margin-bottom:20px;}

#search{
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  background: #505867;
  color: #eee;
  font-size: 18px;
  font-weight: 100;
}

#search::placeholder{color:#ccc;}

.App-link {
  color: #61dafb;
}

#slogan{
  font-style:italic;
  font-size:18px;
  text-align:center;
}

/* PLAYLIST */
.playlist>a{
  text-decoration:none;
  color:#282c34;
}

.cancion{
  display:block;
  padding: 20px;
  border-bottom:1px solid #ddd;
}

.cancion h2,
.cancion p{
  margin:0;
}

.cancion p{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;/*
  display: none;
}

.cancion p:first-of-type {*/
    display: inherit;
}

/* SINGLE */

.single-title{
  background: #4d5b75;
  color: #fff;
  padding: 20px 25px;
}

.lyrics{
  margin-bottom:60px;
  max-width:800px;
  margin:10px auto 60px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}
 
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.button.back{
    background: #282c34;
    width: 70px;
    height: 70px;
    display: block;
    border-radius: 50%;
    color: #fff;
    content: '&larr;';
    position: fixed;
    bottom: 30px;
    left: 30px;
    line-height: 60px;
    text-align: center;
    text-decoration: none;
    font-size: 50px;
}

.song-options{
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999;
}

.option{
    display: block;
    float: left;
    width: 25%;
    text-align: center;
    padding: 15px 0;
    color:#fff;
    font-weight:700;
    color:#fff;
    text-decoration:none;
}

.option.back{background:#4d5b75;}
.option.chords{background:#fecf12;}
.option.audio{background:#f1813e;}
.option.video{background:#e93e3a;}